body {
  background-color: yellowgreen;
}

.inputStyle {
  /* border: 0px !important;
  border-bottom: 2px solid !important; */
  border-radius: 0px !important;
  outline: 0 !important;
  margin-top: 3px;
  border-color: tomato;
  outline: none !important;
  border-inline-end: 1rem solid !important;
  border-bottom-left-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.inputStyle:focus {
  outline: none !important;
}

.spanStyle{

  cursor: pointer;
  color: green;
  margin-left: 20px;
}

.userDetais{
  display: block;
  margin: auto;
}

.productDetails{
  padding: 5px;
  color: gray;
}


.star-rating {
  display: inline-flex;
}

.star {
  width: 20px;
  height: 20px;
  background-image: url('empty.jpeg'); /* Replace with your empty star icon */
  background-size: cover;
  margin-right: 5px;
}

.star.filled {
  background-image: url('fullstar.jpeg'); /* Replace with your filled star icon */
}


.removeCart{
  display: flex;
  justify-content:space-between;
}